export default function(value, from = "pl_PL", to = "en_EN") {

    if(document.getElementsByTagName('html')[0].getAttribute('lang') == 'pl-PL')
        return value;

    const db = [
        {pl_PL: 'dolnośląskie', en_EN: 'Lower Silesia'},
        {pl_PL: 'kujawsko-pomorskie', en_EN: 'Kujawsko-Pomorskie'},
        {pl_PL: 'łódzkie', en_EN: 'Łódzkie'},
        {pl_PL: 'małopolskie', en_EN: 'Lesser Poland'},
        {pl_PL: 'mazowieckie', en_EN: 'Mazovia'},
        {pl_PL: 'podkarpackie', en_EN: 'Subcarpathian'},
        {pl_PL: 'pomorskie', en_EN: 'Pomeranian'},
        {pl_PL: 'śląskie', en_EN: 'Upper Silesia'},
        {pl_PL: 'świętokrzyskie', en_EN: 'Świętokrzyskie'},
        {pl_PL: 'warmińsko-mazurskie', en_EN: 'Warmian-Masurian'},
        {pl_PL: 'wielkopolskie', en_EN: 'Greater Poland'},
        {pl_PL: 'zachodniopomorskie', en_EN: 'West Pomeranian'},
        {pl_PL: 'lubuskie', en_EN: 'Lubusz'},
        {pl_PL: 'opolskie', en_EN: 'Opole'},
        {pl_PL: 'podlaskie', en_EN: 'Podlaskie'},
        {pl_PL: 'lubelskie', en_EN: 'Lublin'},
        {pl_PL: 'Finanse i Księgowość', en_EN: 'Finance and Accounting'},
        {pl_PL: 'Marketing i PR', en_EN: 'Marketing and PR'},
        {pl_PL: 'BHP', en_EN: 'OHS'},
        {pl_PL: 'HR', en_EN: 'HR'},
        {pl_PL: 'IT i Projekty', en_EN: 'IT and Projects'},
        {pl_PL: 'Administracja i Prawo', en_EN: 'Administration and Law'},
        {pl_PL: 'Magazyny i Terminale', en_EN: 'Warehouses and Terminals'},
        {pl_PL: 'Spedycja Drogowa', en_EN: 'Road Freight'},
        {pl_PL: 'Spedycja Morska/Lotnicza/Kolejowa', en_EN: 'Sea/Air/Rail Freight'},
        {pl_PL: 'Agencje Celne', en_EN: 'Customs Agencies'},
        {pl_PL: 'Sprzedaż', en_EN: 'Sales'},
        {pl_PL: 'Stanowiska Biurowe', en_EN: 'Office positions'},
        {pl_PL: 'Obsługa Klienta', en_EN: 'Customer Service'},
        {pl_PL: 'Staże i Praktyki', en_EN: 'Internships and Trainings'},
        {pl_PL: 'Spedycja i Agencje Celne', en_EN: 'Freight Forwarding and Customs Agencies'},
        {pl_PL: 'Sprzedaż i Obsługa Klienta', en_EN: 'Sales and Customer Service'},
        {pl_PL: 'praca stacjonarna', en_EN: 'In-house work'},
        {pl_PL: 'praca hybrydowa', en_EN: 'Hybrid work'},
        {pl_PL: 'praca zdalna', en_EN: 'Remote work'},
        {pl_PL: 'umowa o pracę', en_EN: 'Contract of employment'},
        {pl_PL: 'umowa zlecenie', en_EN: 'Contract of mandate'},
        {pl_PL: 'kontrakt B2B', en_EN: 'B2B contract'},
        {pl_PL: 'Wszystkie', en_EN: 'All'},
        {pl_PL: 'Cała Polska', en_EN: 'All Poland'},
        {pl_PL: 'Polska', en_EN: 'Poland'},
    ];

    let result = db.find(item => item[from] == value);
    if(!result)
        return value;
    return result[to];
}
