import { gsap, Power1 } from "gsap";
import Swiper, { EffectFade, Navigation, Pagination } from 'swiper';
import ScrollTrigger from "gsap/ScrollTrigger";
import 'swiper/swiper.scss';
import 'swiper/modules/effect-fade/effect-fade.scss';
import Plyr from 'plyr';
require('../../node_modules/plyr/dist/plyr.css');

document.addEventListener('DOMContentLoaded',function() {
    gsap.registerPlugin(ScrollTrigger);
    
    let _this;
    
    const opinions = document.querySelector('.c-opinions');

    const init = () => {
        let interval, 
            playerStatus = false,
            speed = 500;
            
        let players = document.querySelectorAll('.js-player'),
            playersArray = new Array();
        
        const opinionsCarousel = () => {
            let swiper, status = false, dep = false, desktop = true;
            
            const carousels = {
                destroy: function(el) {
                    status === true ? swiper.destroy() : false;
                    status = false;
                },
    
                startDesktop: function(el) {
                    swiper = new Swiper(opinions.querySelector('.js-opinionsSwiper'), {
                        allowTouchMove: false,
                        createElements: true,
                        effect: 'fade',
                        fadeEffect: {
                            crossFade: true
                        },
                        modules: [EffectFade, Navigation, Pagination],
                        navigation: {
                            nextEl: document.querySelector('.c-opinions .swiper-button-next'),
                            prevEl: document.querySelector('.c-opinions .swiper-button-prev'),
                        },
                        on: { 
                            init: function() {
                                dep = true;
                               // console.log(swiper.realIndex)
                            },
                            slideChangeTransitionStart: function() {
                                
                                for (let i = 0; i < playersArray.length; i++) {
                                    playersArray[i].pause();
                                }
                                
                                loadVideo(swiper.realIndex);

                                if (dep === true) {
                                    if (window.innerWidth > 768) {
                                        let tl = gsap.timeline();
                                        tl.set(opinions.querySelector('.swiper-slide-active .o-article'), { 
                                           opacity: 0
                                        })
                                        
                                        tl.to(opinions.querySelector('.swiper-slide-active .o-article'), { 
                                           duration: .3, opacity: 1
                                        }, '+=.5')
                                        
                                        tl.to(opinions.querySelector('.swiper-slide-active .o-article'), { 
                                           x: '2rem'
                                        }, '-=1.5')
                                        
                                        tl.to(opinions.querySelector('.swiper-slide-active .o-article'), { 
                                           duration: .5, x: 0, ease: Power1.easeOut
                                        }, '-=.5')
                                    }
                                }
                            },
                        },
                        speed: speed,
            	    });
    
            	    status = true;
            	    desktop = true;
                },
                
                startMobile: function(el) {
                    swiper = new Swiper(opinions.querySelector('.js-opinionsSwiper'), {                     
                        modules: [Navigation, Pagination],
                        navigation: {
                            nextEl: document.querySelector('.c-opinions .swiper-button-next'),
                            prevEl: document.querySelector('.c-opinions .swiper-button-prev'),
                        },
                        pagination: {
                            el: document.querySelector('.c-opinions .swiper-pagination'),
                            clickable: true,
                            dynamicBullets: true,
                            dynamicMainBullets: 1
                        },
                        followFinger: false,
                        slidesPerView: 'auto',
                        //slidesPerGroup: 1,
                        spaceBetween: 10,
                        speed: speed,
                        on: { 
                            slideChangeTransitionStart: function() {
                                for (let i = 0; i < playersArray.length; i++) {
                                    playersArray[i].pause();
                                }
                                
                                loadVideo(swiper.realIndex);
                            }
                        }
            	    });
    
            	    status = true;
            	    desktop = false;
                },
    
                toggle: function() {
                    if (window.innerWidth > 768) {
                        if (status === false) {
                            carousels.startDesktop(opinions)
                        } else {
                            if (desktop === false) {
                                carousels.destroy(opinions);
                                carousels.startDesktop(opinions)
                            }
                        }
                    } else {
                        if (desktop === true) {
                            carousels.destroy(opinions);
                        }
                        
                        if (status === false) {
                            carousels.startMobile();
                        }
                    }
                }
            }
    
            carousels.toggle();
            window.addEventListener('resize', carousels.toggle);
        }
        
        const loadVideo = function(idx) {            
                playersArray[idx] = new Plyr(players[idx], {
                    vimeo: { 
                        sidedock: 0, 
                        controls: 0,
                    },
                    controls: ['play', 'progress', 'volume', 'mute', 'play-large']
                });
            
/*
            let ro = new ResizeObserver(entries => {                        
                ScrollTrigger.refresh();
            });
            
            ro.observe(document.querySelector('.c-opinions'));
*/
        }

        const detectPlayer = function() {
            clearInterval(interval); // for better performance
        
            interval = setInterval(() => {
                let bottomOfObject = opinions.getBoundingClientRect().top + 200,
                bottomOfWindow = window.innerHeight;
        
                if ( bottomOfWindow > bottomOfObject ) {
                    if (playerStatus === false) {
                        //console.log('load player');
                        loadVideo(0);
                        playerStatus = true;
                    }
            	}

            }, 50);        
        }
        
        opinionsCarousel();
        window.addEventListener('scroll', detectPlayer);
        
/*
                
        setTimeout(function() {
            player.source = {
                type: 'video',
                sources: [
                    {
                        src: '648765670',
                        provider: 'vimeo',
                    },
                ],
            };
            
        }, 1000)   
*/     
        
/*
        let widgetPlayer = new Plyr('#player_widget', {
            vimeo: { 
                sidedock: 0, 
                controls: 0,
            },
            controls: ['play', 'progress', 'volume', 'mute', 'play-large']
        });
        
        widgetPlayer.source = {
            type: 'video',
            sources: [
                {
                    src: document.querySelector('.js-videowidget').dataset.url,
                    provider: 'vimeo',
                },
            ],
        };
*/
        
        
    }

    
    
    
    
    opinions ? init() : false;
    
}, false);
