import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', () => {

    const el = document.querySelector('.js-applywidget');
    
    const init = () => {
        const trigger = el.querySelectorAll('.js-trigger');
        
        const hide = () => {
            el.classList.remove('roll-out');
            el.classList.add('icon-only');
            
            Cookies.get('suus-applywidget') != 'hide' ? Cookies.set("suus-applywidget", 'hide', { expires: 356, path: '/' }) : false;
        }
        
        for (let i = 0; i < trigger.length; i++) {
            trigger[i].addEventListener('click', (e) => {
                let _this = e.currentTarget;

                if (el.classList.contains('roll-out')) {
                    if (_this.classList.contains('o-btn')) {
                        window.open(_this.getAttribute('href'), '_self');
                        hide();
                    }
                    
                    if (_this.classList.contains('icon-arrow')) {
                        hide();
                    }
                    
                } else {
                    el.classList.add('roll-out');
                    el.classList.remove('icon-only');
                }
                
                e.preventDefault() ? e.preventDefault() : e.preventDefault = false;  
            });
        }
    }

    el ? init() : false;
    
}, false);
