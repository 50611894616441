import { gsap } from "gsap";
import smoothscroll from 'smoothscroll-polyfill';
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

    window.runScroll = (el, o) => {
        let offsetTop = document.querySelector(el).offsetTop;

        o === undefined ? o = 0 : false;

        if (window.innerWidth <= 768) {
            o += document.querySelector('.c-topbar').clientHeight;
        }
/*
        scroll({
            top: offsetTop - o,
            behavior: "smooth"
        })
*/

        let tl = gsap.timeline();

        gsap.to(window, 1, {
            scrollTo: {
                y: el,
                offsetY: o,
            }, onComplete: function() {
                //console.log('scrolled')
            }
        });

        if (!document.querySelector('.c-menu').classList.contains('is-hidden')) {
            window.hideMenu();
        }

/*
        setTimeout(function() {
            ScrollTrigger.refresh();
        }, 1000)
*/
    };

    const gtt = document.querySelectorAll("[data-target]");

    if (gtt.length > 0) {
        const action = (e) => {
        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
            let target = e.currentTarget.dataset.target,
                offset = e.currentTarget.dataset.offset;

            let url = e.currentTarget.href;

            document.querySelector(target) ? window.runScroll(target, offset) :
                window.open(url + target, '_self');
        };

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }

}, false);
