import { CountUp } from 'countup.js';
import InView from 'inview';

document.addEventListener('DOMContentLoaded', () => {

    const count = document.querySelectorAll('.js-countup');
    
    const init = ()=> {        
        for (let i = 0; i < count.length; i++) {
            const options = {
                duration: 3,
                separator: count[i].dataset.separator
            }

            const inview = InView(count[i], function(isInView) {
                if (isInView) {
                    if (i === 0) {
                        let demo = new CountUp(count[i], count[i].getAttribute('data-count'), options);
                	    !demo.error ? demo.start() : false;
                    } else {   
                        let demo = new CountUp(count[i], count[i].getAttribute('data-count'), options);
                	    !demo.error ? demo.start() : false;
                    }
                    this.destroy();
                }
            });
        }
    };

    count.length > 0 ? init() : false;

}, false);