document.addEventListener('DOMContentLoaded', () => {

    const el = document.querySelectorAll('.js-tabs');
  
    const thisindex = function(elm) {
        let nodes = elm.parentNode.childNodes, node, i = 0, count = 0;
        while( (node = nodes.item(i++)) && node != elm ) {
            if( node.nodeType == 1 ) count++;            
        }
        return count;
    }

    const init = function() {
        
        const toggleTab = function(idx, contentItem, navItem) {
            for (let i = 0; i < contentItem.length; i ++) {
                
                contentItem[i].classList.remove('is-visible');
                
                setTimeout(function() {
                    contentItem[i].classList.remove('is-active');
                    
                    if (i === idx) {
                        contentItem[i].classList.add('is-active');
                        setTimeout(() => {
                            contentItem[i].classList.add('is-visible');                        
                        }, 10);
                    }
                }, 250);                
            }

            for (let j = 0; j < navItem.length; j ++) {
                navItem[j].classList.remove('is-active');
            }
        };

        for (let i = 0; i < el.length; i++) {
            const content = el[i].getElementsByClassName('js-content')[0],
                  contentItem = content.querySelectorAll('.js-tab'),
                  nav = el[i].querySelector('.js-nav'),
                  navItem = nav.querySelectorAll('.js-tab');

            for (let j = 0; j < navItem.length; j ++) {
                navItem[j].addEventListener('click', (e) => {
                    if (!e.currentTarget.classList.contains('is-active')) {
                        toggleTab(thisindex(e.currentTarget), contentItem, navItem);
                        e.currentTarget.classList.add('is-active');
                    }
                    
                    if (e.currentTarget.classList.contains('process')) {
                        if (window.innerWidth > 768) {
                            window.proces_tl[thisindex(e.currentTarget)].play();
                        }
                    }
                });
            }
        }
    }

    el.length > 0 ? init() : false;

}, false);
