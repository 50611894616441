const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

document.addEventListener('DOMContentLoaded', () => {

    const menu = document.querySelector('.js-menu'),
          hamburger = document.querySelectorAll('.js-hamburger');

    const init = () => {
        const bg = menu.querySelector('.c-menu__bg'),
              close = menu.querySelector('.c-menu__close'),
              logo = menu.querySelector('.c-menu .o-logo'),
              nav = menu.querySelectorAll('.c-menu__nav ul li'),
              robot = menu.querySelector('.c-menu__robot'),
              rules = menu.querySelectorAll('.c-menu__rules ul li'),
              social = menu.querySelectorAll('.c-menu__social ul li');
    
        window.hideMenu = () => {
            enableBodyScroll(menu);
            
            menu.classList.remove('with-delays');

            close.classList.remove('is-visible');
            robot.classList.remove('is-visible');
            bg.classList.remove('is-visible');
            logo.classList.remove('is-visible');
            
            for (let i = 0; i < nav.length; i++) {                   
               nav[i].classList.remove('is-visible');
            }
           
            for (let i = 0; i < social.length; i++) {                   
               social[i].classList.remove('is-visible');
            }
            
            for (let i = 0; i < rules.length; i++) {                   
               rules[i].classList.remove('is-visible');
            }
            
            setTimeout(() => {
               menu.classList.add('is-hidden');                
            }, 1000)
        };

        const showMenu = (e) => {
            disableBodyScroll(menu);
            
            menu.classList.remove('is-hidden');
            menu.classList.add('with-delays');
            logo.classList.add('is-visible');
            
            setTimeout(() => {
                bg.classList.add('is-visible');
               
                for (let i = 0; i < nav.length; i++) {                   
                   nav[i].classList.add('is-visible');
                }
               
                for (let i = 0; i < social.length; i++) {                   
                   social[i].classList.add('is-visible');
                }
                
                for (let i = 0; i < rules.length; i++) {                   
                   rules[i].classList.add('is-visible');
                }
            }, 1)
            
            setTimeout(() => {
                close.classList.add('is-visible');
                robot.classList.add('is-visible');
            }, 1000);
        };

        const toggleMenu = () => {
            menu.classList.contains('is-hidden') ? showMenu() : hideMenu();
        }

        for (let i = 0; i < hamburger.length; i++) {
            hamburger[i].addEventListener('click', toggleMenu);
        }

        // Hide menu on ESC
        document.addEventListener('keydown', (evt) => {
            // evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key == "Escape" || evt.key == "Esc");
            } else {
                isEscape = (evt.keyCode == 27);
            }
            if (isEscape) {
                hideMenu();
            }
        });
    };

    menu ? init() : false;

}, false);