import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper.scss';
import { gsap, TweenMax, Power1 } from "gsap";

document.addEventListener('DOMContentLoaded', () => {

    const el = document.querySelector('#areas');

    const init = ()=> {
        let current;
        const el_info = document.querySelector('#areasInfo'),
              box = el.querySelectorAll('.js-box'),
              article = el_info.querySelectorAll('.js-article');
        
        const toggle = (ev, section, op, id) => {
            TweenMax.to(section, 0.4, {
                height: ev,
                opacity: op,
                immediateRender: false,
                ease: Power1.easeOut,
                onComplete: ()=> {
/*
                    if (el.querySelector(".js-item[data-id="+id+"]")) {                       
                       el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                    }
*/
                }
            });
        }
        
        const action = (e)=> {
            const _that = e.currentTarget;
            let id =_that.dataset.id;
            
            if (id != current) {
                for( let i = 0; i < article.length; i ++ ) {
                    toggle(0, article[i], 1);
                    box[i].classList.remove('is-active');
                    article[i].classList.remove('is-active');
                }
                el.querySelector("[data-id="+id+"]").classList.add('is-active');
                toggle('auto', el_info.querySelector('#'+id), 1, id);
                current = id;
            }
        };

        const carousel = () => {
            let mediaQuery = '(max-width: 510px)',
                mediaQueryList = window.matchMedia(mediaQuery),
                swiper;
    
            const start = ()=> {
                current = '';
                
/*
                for (let i = 0; i < box.length; i++) {
                    box[i].classList.remove('is-active');
                }
                
                for (let i = 0; i < article.length; i++) {
                    article[i].classList.remove('is-active');
                }
*/

                swiper = new Swiper(document.querySelector('#areas .swiper'), {
                    modules: [Navigation],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    centeredSlides: 1,
                    speed: 500,
                    slidesPerView: 'auto'
        	    });
        	    
        	    setTimeout(()=> {
            	    swiper.update();
        	    }, 100)
            }
    
            mediaQueryList.addEventListener('change', event => {
                event.matches ? start() : swiper.destroy();
            })

            mediaQueryList.matches ? start() : false;
        }
        
        for( let i = 0; i < box.length; i ++ ) {
            box[i].addEventListener('click', action);
        }
               
        carousel();
    }

    el ? init() : false;

}, false);
