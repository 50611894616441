import { gsap, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', () => {

    const cover = document.querySelector('#cover');
    
    //Cookies.get('suus-applywidget') === 'hide' ? document.querySelector('.js-applywidget').classList.add('icon-only') : false;

    const init = () => {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');
        
/*
        gsap.to(cover, {
            opacity: 0,
            duration: .6
        });
*/
        
        gsap.from(document.querySelector('.c-hero'), {
            scale: 1.1,
            duration: .6,
            ease: Power1.easeOut
        });

        setTimeout(() => {
            window.anims();
            window.carousels();
        });
        
        setTimeout(() => {
            cover.remove();
        }, 500);  
    };
    
    window.addEventListener('load', init);

}, false);