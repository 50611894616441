import { gsap, Power1 } from "gsap";
import GLightbox from '../../node_modules/glightbox/dist/js/glightbox.js';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
require('../../node_modules/glightbox/dist/css/glightbox.css');

document.addEventListener('DOMContentLoaded', () => {
    
    const gallery = document.querySelector('.js-gallery');
    
    const init = function() {
        
        const next = gallery.parentNode.querySelector('.swiper-button-next'),
              prev = gallery.parentNode.querySelector('.swiper-button-prev');
        
        const gallery_item = gallery.querySelectorAll('.o-item');
        
        let gallery_array = new Array(),
            href_array = new Array(),
            class_array = new Array(),
            video;

        for (let i = 0; i < gallery_item.length; i ++) {
            gallery_array[i] = gallery_item[i].getElementsByTagName('img')[0].getAttribute('src');
            href_array[i] = gallery_item[i].getElementsByTagName('a')[0].getAttribute('href'),
            class_array[i] = gallery_item[i].getElementsByTagName('a')[0].getAttribute('class');
        }
        
        gallery_array.reverse();
        href_array.reverse();
        class_array.reverse();
        
        const init_glightbox = () => {
            if (document.getElementsByClassName('js-video').length > 0) {
                video = GLightbox({
                    selector: '.js-video'
                });
            }
        }

        const nextPhoto = (item, sidx)=> {
            if (item.def) {
                item.k ++;
            } else {
                item.def = true;
                item.k = sidx;
            }

            item.k >= gallery_array.length ? item.k = 0 : false;
            
/*
            let tl = gsap.timeline();            
            tl.to(item, { 
               duration: .5, 
               opacity: 0,
               scale: .9,
               ease: "elastic.out(1, .8)",
               onStart: () => {
                   item.querySelector('img').classList.add('.fadeOut');
                },
               onComplete: () => {
                   item.querySelector('img').setAttribute('src', gallery_array[ item.k ]);
                   item.querySelector('img').classList.remove('.fadeOut');
                }
            })
            
            tl.to(item, { 
               duration: .25, 
               opacity: 1,
               scale: 1,
               ease: "elastic.out(.5, .8)"
            }, '+=.05')   
*/ 
            
            item.querySelector('a').setAttribute('class', class_array[ item.k ]);
            item.querySelector('a').setAttribute('href', href_array[ item.k ]);
            item.querySelector('img').setAttribute('src', gallery_array[ item.k ]);  
            
            setTimeout(function() {
                if (video) {
                    video.destroy();
                }
                init_glightbox();
            }, 10)
        }
        
        const prevPhoto = (item, sidx)=> {
            if (item.def) {
                item.k --;
            } else {
                item.def = true;
                item.k = sidx - 1;
            }

            item.k < 0 ? item.k = gallery_array.length - 1 : false;

            item.querySelector('a').setAttribute('class', class_array[ item.k ]);
            item.querySelector('a').setAttribute('href', href_array[ item.k ]);
            item.querySelector('img').setAttribute('src', gallery_array[ item.k ]);
            
            setTimeout(function() {
                if (video) {
                    video.destroy();
                }
                init_glightbox();
            }, 10)
        }

        next.addEventListener('click', (e)=> {
            for (let i = 0; i < gallery_item.length; i++) {
                if (window.innerWidth > 768) {
                    nextPhoto(gallery_item[i], gallery_item.length - i);
                }
            }            

            // e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        })
        
        prev.addEventListener('click', (e)=> {
            for (let i = 0; i < gallery_item.length; i++) {
                if (window.innerWidth > 768) {
                    prevPhoto(gallery_item[i], gallery_item.length - i - 1);
                }
            } 

            // e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        })
        
        init_glightbox();
        
        const galleryCarousel = ()=> {
            let swiper, status = false, speed = 500;
            
            const carousels = {
                destroy: function(el) {
                    status === true ? swiper.destroy() : false;
                    status = false;
                },
    
                start: function(el) {
                    swiper = new Swiper(gallery, {
                        modules: [Navigation, Pagination],
                        breakpoints: {
                            501: {
                        	    spaceBetween: 20,
                        	    slidesPerGroup: 2,
                                slidesPerView: 2,
                            },
                        },
                        followFinger: false,
                        navigation: {
                            nextEl: document.querySelector('.c-gallery .swiper-button-next'),
                            prevEl: document.querySelector('.c-gallery .swiper-button-prev'),
                        },
                        pagination: {
                            el: document.querySelector('.c-gallery').querySelector('.swiper-pagination'),
                            clickable: true
                        },
                        slidesPerView: 'auto',
                        spaceBetween: 10,
                        speed: speed
                    })
                    
                    status = true;
                },
    
                toggle: function() {
                    if (window.innerWidth <= 768) {
                        status === false ? carousels.start(gallery) : false;
                    } else {
                        carousels.destroy(gallery);
                    }
                }
            }
    
            carousels.toggle();
            window.addEventListener('resize', carousels.toggle);
        }
        
        galleryCarousel();
    }
    
    gallery ? init() : false;

}, false)
