import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Plyr from 'plyr';
require('../../node_modules/plyr/dist/plyr.css');

document.addEventListener('DOMContentLoaded', ()=> {
    gsap.registerPlugin(ScrollTrigger);
    
    const roboVideo = document.querySelectorAll('.js-roboVideo');
    
    const initRoboVideo = ()=> {
        
        for (let i = 0; i < roboVideo.length; i++) {
            const videos = roboVideo[i].dataset.videos.split(','),
                  randomNum = Math.floor(Math.random() * (videos.length - 1) + 1) ;
                  //randomNum = Math.floor(Math.random() * videos.length);
            let custom;
           
            custom = roboVideo[i].dataset.custom.split(',');
            custom[randomNum] != '' ? roboVideo[i].classList.add(custom[randomNum].trim()) : false;
            
            roboVideo[i].querySelector('source').dataset.src = videos[randomNum].trim();
            const player = new Plyr(roboVideo[i], {
                controls: '',
                ratio: '4:3'
            });

            player.muted = true;
            
            ScrollTrigger.matchMedia({
                "(min-width: 769px)": () => {
                    ScrollTrigger.create({
                        trigger: roboVideo[i],
                        start: "top top",
                        onEnter: ()=> {
                            player.play();
                        }
                    });
                }
            });
        }
    }    
    
    roboVideo.length > 0 ? initRoboVideo() : false;
    
}, false);
