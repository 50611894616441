import Vue from "vue";
import vSelect from "vue-select";
import translate from "./translate";

Vue.component("v-select", vSelect);

window.offerSearchComponent = null;
window.setVue = function(vuecomponent) {
    window.offerSearchComponent = vuecomponent;
}

if(document.getElementById('app-erecruter')) {

    var app  = new Vue({
    el: '#app-erecruter',
    data: {
        settings: {
            // url: 'https://s-api.dlv.pl',
            url: 'https://apikariera.suus.com',
            key: 'qfasg-4g73d-0kfewa-1dy6nc',
            countriesEnabled: true,
            citiesEnabled: true,
            titlesEnabled: false,
            regionsEnabled: true,
            departmentsEnabled: true,
            categoriesEnabled: true,
            loadListAfterStart: false,
            loaderClassName: 'lds-ripple',
            loaderVisibleClassName: 'is-visible',
            contentClassName: 'content',
        },

        countries: [],
        cities: [],
        titles: [],
        categories: [],
        departments: [],
        regions: [],
        list: [],
        city: null,
        title: null,
        country: null,
        region: null,
        department: null,
        category: null,
        search: '',
        search2: '',
        isOpen: false,
        timeout: null,
        showContentSearch: false,
        showPagination: false,
        showListWithResults: false,
        showListWithoutResults: false,
        showJsCloseResults: false,
        excludeCategoryFromReset: false,

        // pagination vars
        currentPage: 1,
        totalPages: 1,
        perPage: 5,
        indexOfLastOffer: 1,
        indexOfFirstOffer: 1,
        currentOffers: [],
    },
    created: function () {
        setVue(this);
    },
    mounted: function(){
        let that = this;

        //setting category form dataset
        if(this.$el.dataset.category){
            this.excludeCategoryFromReset = true;
          this.category = this.$el.dataset.category;
        }

        if (this.settings.citiesEnabled) {
            this.request("/api/locations/" + this.settings.key, function (data) {
                data = that.sortData(data);
                if(!that.region) {
                    data.unshift(translate('Cała Polska'));
                }
                that.cities = data;
            });
        }
        if (this.settings.regionsEnabled) {
            this.request("/api/regions/" + this.settings.key, function (data) {
                data = data.map(function (item) {
                    return translate(item);
                });
                data = that.sortData(data);
                data.unshift(translate('Wszystkie'));
                that.regions = data;
            });
        }
        if (this.settings.departmentsEnabled) {
            let suffix = '';
            if(this.category){
                suffix = '?category='+this.category;
            }
            this.request("/api/departments/" + this.settings.key + suffix, function (data) {
                data = data.map(function (item) {
                    return translate(item);
                });
                data = that.sortData(data);
                data.unshift(translate('Wszystkie'));
                that.departments = data;
            });
        }
        if (this.settings.categoriesEnabled) {
            this.request("/api/categories/" + this.settings.key, function (data) {
                data = data.map(function (item) {
                    return translate(item);
                });
                data = that.sortData(data);
                data.unshift(translate('Wszystkie'));
                that.categories = data;
            });
        }

        if (this.settings.countriesEnabled) {
            let endpointUrl = "/api/country/" + this.settings.key;
            this.request(endpointUrl, function (data) {
                data = data.map(function (item) {
                    return translate(item);
                });
                data = that.sortData(data);
                data.unshift(translate('Wszystkie'));
                that.countries = data;
            });
        }

        if (this.settings.loadListAfterStart) {
            this.getList();
        }
    },
    watch: {
        city: function (newVal, oldVal) {
            // this.selected();
        },
        country: function (newVal, oldVal) {
            // this.selected();
            if(newVal != oldVal){
                this.city = null;
                this.region = null;

                if(translate(newVal,'en_EN','pl_PL') == 'Wszystkie') newVal = null;

                newVal = translate(newVal,'en_EN','pl_PL')

                this.request("/api/regions/" + this.settings.key + '?country='+newVal,  (data) => {
                    data = data.map(function (item) {
                        return translate(item);
                    });
                    this.regions = this.sortData(data);
                    if(!newVal) {
                        data.unshift(translate('Wszystkie'));
                    }
                });

                this.request("/api/locations/" + this.settings.key + '?country='+newVal,  (data) => {
                    this.cities = this.sortData(data);
                    if(!newVal) {
                        data.unshift(translate('Wszystkie'));
                    }
                });
            }
        },
        category: function (newVal, oldVal) {
            // this.selected();
            if(newVal != oldVal){
                this.city = null;
                this.department = null;

                if(translate(newVal,'en_EN','pl_PL') == 'Wszystkie') newVal = null;

                this.request("/api/departments/" + this.settings.key + '?category='+translate(newVal,'en_EN','pl_PL'),  (data) => {
                    data = data.map(function (item) {
                        return translate(item);
                    });
                    this.departments = this.sortData(data);
                    if(!newVal) {
                        data.unshift(translate('Wszystkie'));
                    }
                });
            }
        },
        region: function (newVal, oldVal) {
            // this.selected();
            if(newVal != oldVal){
                this.city = null;

                if(translate(newVal,'en_EN','pl_PL') == 'Wszystkie') newVal = null;

                this.request("/api/locations/" + this.settings.key + '?region='+translate(newVal,'en_EN','pl_PL'),  (data) => {
                    this.cities = this.sortData(data);
                    if(!newVal){
                        data.unshift(translate('Cała Polska'));
                    }
                });
            }
        },
        department: function (newVal, oldVal) {
            // this.selected();
        }
    },
    computed: {
        lang: function () {
            return document.getElementsByTagName('html')[0].getAttribute('lang');
        },
        searchLabel: function () {
            return this.lang == 'en-EN' ? 'Search' :'Szukaj';
        },
        categoryLabel: function () {
            return this.lang == 'en-EN' ? 'Category' :'Kategoria';
        },
        cityLabel: function () {
            return this.lang == 'en-EN' ? 'City' :'Miasto';
        },
        departmentLabel: function () {
            return this.lang == 'en-EN' ? 'Department' :'Dział';
        },
        countryLabel: function () {
            return this.lang == 'en-EN' ? 'Country' :'Kraj';
        },
        voivodeshipLabel: function () {
            return this.lang == 'en-EN' ? 'Voivodeship' :'Województwo';
        },
        resetLabel: function () {
            return this.lang == 'en-EN' ? 'Reset filters' :'Resetuj filtry';
        },
        allLabel: function () {
            return this.lang == 'en-EN' ? 'All' :'Wszystkie';
        },
        showAllLabel: function () {
            return this.lang == 'en-EN' ? 'Show all offers' :'Pokaż wszystkie oferty';
        },
        searchUrl: function () {
            return this.lang == 'en-EN' ? 'search.html' :'wyszukiwarka.html';
        },
    },
    methods: {
        toggleList: function() {
            if (this.showContentSearch) {
                this.showJsCloseResults = false;
                this.closeBox();
            } else {
                this.openBox();
            }
        },
        clearForm: function () {
            this.city = null;
            if(!this.excludeCategoryFromReset)
                this.category = null;
            this.region = null;
            this.department = null;
            this.country = null;
        },
        openBox: function () {
            this.showContentSearch = true;
            this.isOpen = true;
        },
        closeBox: function () {
            this.showContentSearch = false;
            this.isOpen = false;
        },
        showPreloader: function() {
            if (this.settings.loaderClassName) {
                document.getElementsByClassName(this.settings.loaderClassName)[0].classList.add(this.settings.loaderVisibleClassName);
            }
            this.showJsCloseResults = false;
        },
        hidePreloader: function() {
            if (this.settings.loaderClassName) {
                document.getElementsByClassName(this.settings.loaderClassName)[0].classList.remove(this.settings.loaderVisibleClassName);
            }
        },
        hideList: function() {
            this.showListWithResults = false;
            this.showListWithoutResults = false;
        },
        openTitle: function(title) {
            this.title = title;
            this.getList();
        },
        openCity: function(city) {
            this.city = city;
            this.getList();
        },
        openCategory: function(category) {
            this.category = category;
            // this.getList();
        },
        selected: function () {
            if (this.isOpen) {
                this.getList();
            }
        },
        searchOnChange: function () {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.getList, 300);
        },
        clearSearch: function () {
            this.search = '';
            this.selected();
        },
        search2OnChange: function () {
            this.searchOnChange()
        },
        clearSearch2: function () {
            this.search2 = '';
            this.selected();
        },
        getList: function () {
            let params = [];

            if(this.category){
                params.push('category='+translate(this.category,'en_EN','pl_PL'));
            }
            if(this.department){
                params.push('department='+translate(this.department,'en_EN','pl_PL'));
            }
            if(this.region){
                params.push('region='+translate(this.region,'en_EN','pl_PL'));
            }
            if(this.city){
                params.push('city='+translate(this.city,'en_EN','pl_PL'));
            }
            if(this.country){
                params.push('country='+this.country);
            }

            if(params.length){
                params = "?"+params.join('&');
            } else {
                params = '';
            }

            window.open(this.searchUrl+params,'_self');
        },
        reloadPagination: function() {
            this.totalPages = Math.ceil(this.list.length / this.perPage);
            this.indexOfLastOffer = this.currentPage * this.perPage;
            this.indexOfFirstOffer = this.indexOfLastOffer - this.perPage;
            this.currentOffers = this.list.slice(this.indexOfFirstOffer, this.indexOfLastOffer);
            this.showPagination = this.list.length > this.perPage;
        },
        setCurrentPage: function(page) {
            this.currentPage = page;
            this.reloadPagination();
        },
        nextPage: function() {
            if(this.currentPage < this.totalPages) {
                this.setCurrentPage(this.currentPage + 1);
            }
        },
        prevPage: function() {
            if(this.currentPage > 1) {
                this.setCurrentPage(this.currentPage - 1);
            }
        },
        request: function (url, callback) {
            var request = new XMLHttpRequest();
            var that = this;
            that.showPreloader();
            that.hideList();
            request.onreadystatechange = function() {
                if(request.readyState === 4 && request.status === 200) {
                    that.hidePreloader();
                } else {
                    that.showPreloader();
                }
            }

            request.open('GET', this.settings.url + url, true);

            request.onload = function() {
                if (this.status >= 200 && this.status < 400) {
                    callback(JSON.parse(this.response))
                }
            };
            request.send();
        },
        sortData: function (data){
            return data.sort(function (a,b) {return a.localeCompare(b,"pl")});
        }
    }
})
}
