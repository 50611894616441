import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', ()=> {

    const el = document.querySelector('.js-cookies');

    const init = function() {
        const accept = el.querySelector('.js-accept');

        Cookies.get('rohlig-suus') != 1 ? el.classList.remove('move-out') : false;

        accept.addEventListener('click', (e)=> {
            e.preventDefault();
            Cookies.set("rohlig-suus", 1, { expires: 356, path: '/' });
            el.classList.add('move-out');
        });
    };
    
    el ? init() : false;

}, false);
