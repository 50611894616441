import Vue from "vue";
import vSelect from "vue-select";
import {loadMap, getMapKey} from "./maptiler";
import translate from "./translate";

Vue.component("v-select", vSelect);


if(document.getElementById('app-search-erecruter')) {


    window.offerSearchComponent = null;
    window.setVue = function(vuecomponent) {
        window.offerSearchComponent = vuecomponent;
    }

    const NOCV_CATEGORY = 'Magazyny i Terminale';

    const queryString = window.location;

    let city,region,department,category,country

    if (queryString) {
        let url = new URL(queryString),
            params = new URLSearchParams(url.search);
        city = params.get('city');
        region = params.get('region');
        department = params.get('department');
        category = params.get('category');
        country = params.get('country');
    }

    var erecruterApp = new Vue({
        el: '#app-search-erecruter',
        data: {
            settings: {
                // url: 'https://s-api.dlv.pl',
                url: 'https://apikariera.suus.com',
                key: 'qfasg-4g73d-0kfewa-1dy6nc',
                countriesEnabled: true,
                citiesEnabled: true,
                titlesEnabled: false,
                regionsEnabled: true,
                departmentsEnabled: true,
                categoriesEnabled: true,
                loadListAfterStart: true,
                loaderClassName: 'lds-ripple',
                loaderVisibleClassName: 'is-visible',
                contentClassName: 'content',
            },

            countries: [],
            cities: [],
            titles: [],
            categories: [],
            departments: [],
            regions: [],
            list: [],
            city: translate(city),
            title: null,
            country: country,
            region: translate(region),
            department: translate(department),
            category: translate(category),
            search: '',
            search2: '',
            isOpen: false,
            timeout: null,
            showContentSearch: false,
            showPagination: false,
            showListWithResults: false,
            showListWithoutResults: false,
            showJsCloseResults: false,

            // pagination vars
            currentPage: 1,
            totalPages: 1,
            perPage: 5,
            indexOfLastOffer: 1,
            indexOfFirstOffer: 1,
            currentOffers: [],
            showPages: [],
        },
        created: function () {
            setVue(this);
        },
        mounted: function(){
            let that = this;
            that.hideList();
            if (this.settings.citiesEnabled) {
                let endpointUrl = "/api/locations/" + this.settings.key;
                if(this.region){
                    endpointUrl += "?region=" + this.region;
                }
                this.request(endpointUrl, function (data) {
                    data = data.map(function (item) {

                        return translate(item);
                    });

                    data = that.sortData(data);
                    if(!that.region) {
                        data.unshift(translate('Cała Polska'));
                    }
                    that.cities = data;
                });
            }
            if (this.settings.regionsEnabled) {
                this.request("/api/regions/" + this.settings.key, function (data) {
                    data = data.map(function (item) {
                        return translate(item);
                    });
                    data = that.sortData(data);
                    data.unshift(translate('Wszystkie'));
                    that.regions = data;
                });
            }
            if (this.settings.departmentsEnabled) {
                let endpointUrl = "/api/departments/" + this.settings.key;
                if(this.category){
                    endpointUrl += "?category=" + this.category;
                }
                this.request(endpointUrl, function (data) {
                    data = data.map(function (item) {
                        return translate(item);
                    });
                    data = that.sortData(data);
                    if(!that.category) {
                        data.unshift(translate('Wszystkie'));
                    }
                    that.departments = data;
                });
            }
            if (this.settings.categoriesEnabled) {
                let endpointUrl = "/api/categories/" + this.settings.key;
                this.request(endpointUrl, function (data) {
                    data = data.map(function (item) {
                        return translate(item);
                    });
                    data = that.sortData(data);
                    data.unshift(translate('Wszystkie'));
                    that.categories = data;
                });
            }

            if (this.settings.countriesEnabled) {
                let endpointUrl = "/api/country/" + this.settings.key;
                this.request(endpointUrl, function (data) {
                    data = data.map(function (item) {
                        return translate(item);
                    });
                    data = that.sortData(data);
                    data.unshift(translate('Wszystkie'));
                    that.countries = data;
                });
            }

            if (this.settings.loadListAfterStart) {
                this.getList();
            }
        },
        computed: {
            lang: function () {
                return document.getElementsByTagName('html')[0].getAttribute('lang');
            },
            searchLabel: function () {
                return this.lang == 'en-EN' ? 'Search' :'Szukaj';
            },
            categoryLabel: function () {
                return this.lang == 'en-EN' ? 'Category' :'Kategoria';
            },
            cityLabel: function () {
                return this.lang == 'en-EN' ? 'City' :'Miasto';
            },
            departmentLabel: function () {
                return this.lang == 'en-EN' ? 'Department' :'Dział';
            },
            countryLabel: function () {
                return this.lang == 'en-EN' ? 'Country' :'Kraj';
            },
            voivodeshipLabel: function () {
                return this.lang == 'en-EN' ? 'Voivodeship' :'Województwo';
            },
            resetLabel: function () {
                return this.lang == 'en-EN' ? 'Reset filters' :'Resetuj filtry';
            },
            allLabel: function () {
                return this.lang == 'en-EN' ? 'All' :'Wszystkie';
            },
            showAllLabel: function () {
                return this.lang == 'en-EN' ? 'Show all offers' :'Pokaż wszystkie oferty';
            },
            learnMoreLabel: function () {
                return this.lang == 'en-EN' ? 'Learn more' :'Dowiedz się więcej';
            },
            searchUrl: function () {
                return this.lang == 'en-EN' ? 'search.html' :'wyszukiwarka.html';
            },
            jobOfferUrl: function () {
                return this.lang == 'en-EN' ? 'job-offer.html' :'ogloszenie.html';
            },
            mainTitleLabel: function () {
                return this.lang == 'en-EN' ? 'Search job offers' :'Wyszukaj oferty pracy';
            },
            allJobsLabel: function () {
                return this.lang == 'en-EN' ? 'All job offers' :'Wszystkie oferty pracy';
            },
            weFoundLabel: function () {
                return this.lang == 'en-EN' ? 'We found' :'Znaleźliśmy dla Ciebie';
            },
            offersForYouLabel: function () {
                return this.lang == 'en-EN' ? 'offers for you' :'ofert';
            },
            showOffersLabel: function () {
                return this.lang == 'en-EN' ? 'Show offers' :'Pokaż oferty';
            },
            cvLabel: function () {
                return this.lang == 'en-EN' ? 'To apply for warehouse positions you don\'t need to upload your CV' :'Na stanowiska w magazynie nie musisz załączać CV';
            },
            fillFormLabel: function () {
                return this.lang == 'en-EN' ? 'Fill in the form' :'Wypełnij formularz';
            },
        },
        watch: {
            city: function (newVal, oldVal) {
                // this.selected();
            },
            country: function (newVal, oldVal) {
                // this.selected();
                if(newVal != oldVal){
                    this.city = null;
                    this.region = null;

                    if(translate(newVal,'en_EN','pl_PL') == 'Wszystkie') newVal = null;

                    this.request("/api/regions/" + this.settings.key + '?country='+newVal,  (data) => {
                        data = data.map(function (item) {
                            return translate(item);
                        });
                        this.regions = this.sortData(data);
                        if(!newVal) {
                            data.unshift(translate('Wszystkie'));
                        }
                    });

                    this.request("/api/locations/" + this.settings.key + '?country='+newVal,  (data) => {
                        this.cities = this.sortData(data);
                        if(!newVal) {
                            data.unshift(translate('Wszystkie'));
                        }
                    });
                }
            },
            category: function (newVal, oldVal) {
                // this.selected();
                if(newVal != oldVal){
                    this.city = null;
                    this.department = null;

                    if(translate(newVal,'en_EN','pl_PL') == 'Wszystkie') newVal = null;

                    this.request("/api/departments/" + this.settings.key + '?category='+translate(newVal,'en_EN','pl_PL'),  (data) => {
                        data = data.map(function (item) {
                            return translate(item);
                        });
                        this.departments = this.sortData(data);
                        if(!newVal) {
                            data.unshift(translate('Wszystkie'));
                        }
                    });
                }
            },
            region: function (newVal, oldVal) {
                // this.selected();
                if(newVal != oldVal){
                    this.city = null;

                    if(translate(newVal,'en_EN','pl_PL') == 'Wszystkie') newVal = null;

                    this.request("/api/locations/" + this.settings.key + '?region='+newVal,  (data) => {
                        this.cities = this.sortData(data);
                        if(!newVal){
                            data.unshift(translate('Cała Polska'));
                        }
                    });
                }
            },
            department: function (newVal, oldVal) {
                // this.selected();
            }
        },
        methods: {
            toggleList: function() {
                if (this.showContentSearch) {
                    this.showJsCloseResults = false;
                    this.closeBox();
                } else {
                    this.openBox();
                }
            },
            clearForm: function () {
                this.city = null;
                this.category = null;
                this.region = null;
                this.department = null;
                this.country = null;
            },
            openBox: function () {
                this.showContentSearch = true;
                this.isOpen = true;
            },
            closeBox: function () {
                this.showContentSearch = false;
                this.isOpen = false;
            },
            showPreloader: function() {
                // if (this.settings.loaderClassName) {
                //     document.getElementsByClassName(this.settings.loaderClassName)[0].classList.add(this.settings.loaderVisibleClassName);
                // }
                // this.showJsCloseResults = false;
            },
            hidePreloader: function() {
                if (this.settings.loaderClassName) {
                    // document.getElementsByClassName(this.settings.loaderClassName)[0].classList.remove(this.settings.loaderVisibleClassName);
                }
            },
            hideList: function() {
                this.showListWithResults = false;
                this.showListWithoutResults = false;
            },
            openTitle: function(title) {
                this.title = title;
                this.getList();
            },
            openCity: function(city) {
                this.city = city;
                this.getList();
            },
            openCategory: function(category) {
                this.category = category;
                this.getList();
            },
            selected: function () {
                if (this.isOpen) {
                    this.getList();
                }
            },
            searchOnChange: function () {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(this.getList, 300);
            },
            clearSearch: function () {
                this.search = '';
                this.selected();
            },
            search2OnChange: function () {
                this.searchOnChange()
            },
            clearSearch2: function () {
                this.search2 = '';
                this.selected();
            },
            setMarker: function (lng, lat) {
                if (this.isOpen) {
                    if (lng && lat) {
                        this.list = this.listBackup.filter((item) => item.latitude === lat && item.longitude === lng);
                    } else {
                        this.list = this.listBackup;
                    }
                    this.setCurrentPage(1);
                }
            },
            getList: function (scrollToResult = false) {
                let that = this;
                setTimeout(function () {
                    let city = translate(that.city,'en_EN','pl_PL') === 'Cała Polska' ? null : that.city;
                    let category = translate(that.category,'en_EN','pl_PL') === 'Wszystkie' ? null : that.category;
                    let department = translate(that.department,'en_EN','pl_PL') === 'Wszystkie' ? null : that.department;
                    let region = translate(that.region,'en_EN','pl_PL') === 'Wszystkie' ? null : that.region;
                    let country = translate(that.country,'en_EN','pl_PL') === 'Wszystkie' ? null : that.country;
                    let url = '';

                    if (city || category || department || region || country) {
                        url = '?';
                        if (city) {
                            url += 'location=' + translate(city,'en_EN','pl_PL') + '&';
                        }
                        if (category) {
                            url += 'category=' + translate(category,'en_EN','pl_PL') + '&';
                        }
                        if (region) {
                            url += 'countryRegionName=' + translate(region,'en_EN','pl_PL') + '&';
                        }
                        if (department) {
                            url += 'department=' + translate(department,'en_EN','pl_PL') + '&';
                        }
                        if (country) {
                            url += 'country2=' + translate(country,'en_EN','pl_PL') ;
                        }
                    }

                    that.request("/api/list/" + that.settings.key + url, function( data ) {
                        that.currentPage = 1;
                        that.setCurrentPage(that.currentPage);
                        that.list = data;
                        that.listBackup = that.list;
                        if (that.list.length > 0) {
                            that.showListWithResults = true;
                            that.showListWithoutResults = false;
                            that.showJsCloseResults = true;
                        } else {
                            that.showListWithResults = false;
                            that.showListWithoutResults = true;
                        }

                        setTimeout(function () {loadMap(false, that.list, null, null, true)});

                        that.reloadPagination();

                        if(scrollToResult === true){
                            if (that.list.length > 0)
                                setTimeout(()=>window.runScroll('#results', 0));
                            else
                                setTimeout(()=>window.runScroll('#nooffer', 0));
                        }

                    });

                    that.openBox();
                })
            },
            reloadPagination: function() {
                this.totalPages = Math.ceil(this.list.length / this.perPage);
                this.indexOfLastOffer = this.currentPage * this.perPage;
                this.indexOfFirstOffer = this.indexOfLastOffer - this.perPage;
                this.currentOffers = this.list.slice(this.indexOfFirstOffer, this.indexOfLastOffer);

                if (this.totalPages < 4) {
                    this.showPages = this.range(1, this.totalPages);
                } else if (this.currentPage - 1 > 0 && this.currentPage + 1 < this.totalPages) {
                    this.showPages = this.range(this.currentPage - 1, this.currentPage + 1);
                } else if (this.currentPage - 1 < 1) {
                    this.showPages = this.range(1, 3);
                } else {
                    this.showPages = this.range(this.totalPages - 2, this.totalPages);
                }

                this.showPagination = this.list.length > this.perPage;
            },
            range: function (from, to) {
                let arr = [];
                for(let i = from; i <= to; ++i) {
                    arr.push(i);
                }
                return arr;
            },
            setCurrentPage: function(page) {
                this.currentPage = page;
                this.reloadPagination();
            },
            nextPage: function() {
                if(this.currentPage < this.totalPages) {
                    this.setCurrentPage(this.currentPage + 1);
                }
            },
            prevPage: function() {
                if(this.currentPage > 1) {
                    this.setCurrentPage(this.currentPage - 1);
                }
            },
            goTo: function (url){
                window.open(url,'_self');
            },
            request: function (url, callback) {
                var request = new XMLHttpRequest();
                var that = this;
                that.showPreloader();
                // that.hideList();
                request.onreadystatechange = function() {
                    if(request.readyState === 4 && request.status === 200) {
                        that.hidePreloader();
                    } else {
                        that.showPreloader();
                    }
                }

                request.open('GET', this.settings.url + url, true);

                request.onload = function() {
                    if (this.status >= 200 && this.status < 400) {
                        callback(JSON.parse(this.response))
                    }
                };
                request.send();
            },

            getNocvList(){
                this.category = '';
                setTimeout(()=>{
                    this.city = '';
                    this.region = '';
                    this.category = NOCV_CATEGORY;
                    this.getList(true);
                })
            } ,
            sortData: function (data){
                return data.sort(function (a,b) {return a.localeCompare(b,"pl")});
            },
            translate: function (value) {
                return translate(value);
            }
        }
    })
}
