import css from './sass/style.scss';

require('./js/anims.js');
require('./js/applywidget.js');
require('./js/areas.js');
require('./js/carousels.js');
require('./js/countup.js');
require('./js/cookies.js');
require('./js/custom-select.js');
require('./js/erecruter.js');
require('./js/erecruter-offer.js');
require('./js/erecruter-search.js');
require('./js/gallery.js');
require('./js/glightbox.js');
require('./js/init.js');
require('./js/ismobile.js');
require('./js/lazy.js');
require('./js/menu.js');
require('./js/opinions.js');
require('./js/polyfills.js');
require('./js/smoothscroll.js');
require('./js/social.js');
require('./js/tabs.js');
require('./js/validate.js');
require('./js/video.js');
