import { gsap, Linear, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    
    window.anims = () => { 
        const fadeIn = () => {
            gsap.utils.toArray(".fadeIn > *").forEach(function(section) {
                gsap.from(section, {
                    scrollTrigger: {
                        trigger: section,
                        start: '-50px bottom',
                        end: 'bottom',
                        toggleActions: "play complete complete reset",
                    },
                    opacity: 0, 
                    duration: .5,
                    y: 50
                });
            });
        }
               
        const hexagon = () => {
            const items = gsap.utils.toArray(".o-hexagon"); 
            
            items.forEach((item, i) => {
                gsap.from(item, {
                    scrollTrigger: {
                        trigger: item,
                        start: 'top bottom',
                        scrub: 1.5,
                        toggleActions: "play complete complete reset",
                    }, 
                    opacity: 0,
                    xPercent: 10
                });
            });
        }
       
        const process = () => {
    
            const items = gsap.utils.toArray(".c-process .swiper");
            window.proces_tl = new Array();
            
            ScrollTrigger.matchMedia({
                "(min-width: 769px)": () => {
                    gsap.from(document.querySelector('.c-process'), {
                        scrollTrigger: {
                            trigger: '.c-process',
                            start: 'top top',
                            onEnter: () => {
                                toggleTimeline(true);
                            },
                            onLeaveBack: () => {
                                toggleTimeline(true);
                            },
                            onLeave: () => {
                                toggleTimeline(false);
                            }
                        }, 
                    });
                    
                    
                    
                    items.forEach((item, i) => {
                        
                        if(item.classList.contains('office')) {
    
                            const block1 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(1)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(1)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(1)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                            
                            const block2 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(2)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(2)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(2)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                            
                            const block3 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(3)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(3)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(3)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                            
                            const block4 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(4)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(4)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(4)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                            
                            const block5 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(5)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(5)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(5)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                            
                            proces_tl[i] = gsap.timeline({
                                repeat: -1
                            }).pause()
                                .add(block1())
                                .add(block2())
                                .add(block3())
                                .add(block4())
                                .add(block5())
                        }
                        
                        if(item.classList.contains('courses')) {
    
                            const block1 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(1)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(1)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(1)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                            
                            const block2 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(2)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(2)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(2)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                            
                            const block3 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(3)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(3)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(3)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                            
                            const block4 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(5)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(5)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(5)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                           
                            
                            proces_tl[i] = gsap.timeline({
                                repeat: -1
                            }).pause()
                                .add(block1())
                                .add(block2())
                                .add(block3())
                                .add(block4())
                        }
                        
                        if(item.classList.contains('storage')) {
    
                            const block1 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(1)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(1)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(1)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                            
                            const block2 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(2)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(2)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(2)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                            
                            const block5 = () => {
                                item.tl = gsap.timeline()
                                .to(item.querySelector(".swiper-slide:nth-of-type(5)"), {
                                    duration: 3,
                                    onStart: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(5)").classList.add('is-active');
                                    },
                                    onComplete: () => {
                                        item.querySelector(".swiper-slide:nth-of-type(5)").classList.remove('is-active');
                                    }
                                });
                                return item.tl;
                            };
                            
                            proces_tl[i] = gsap.timeline({
                                repeat: -1
                            }).pause()
                                .add(block1())
                                .add(block2())
                                .add(block5())
                        }
                    })
                }
            });
                        
            const toggleTimeline = (i) => {
                if (document.querySelector('.c-process').querySelector('.js-nav')) {
                    let tabs = document.querySelector('.c-process').querySelector('.js-nav').querySelectorAll('.js-tab');
                    if (i === true) {
                        for (let i = 0; i < tabs.length; i++) {
                            proces_tl[i].pause();
                            if (tabs[i].classList.contains('is-active')) {
                                proces_tl[i].play();                        
                            }
                        }
                    } else {
                        for (let i = 0; i < tabs.length; i++) {
                            proces_tl[i].pause();
                        }
                    }
                } else {
                    proces_tl[0].play();
                }
            }            
        }
        
        const recruitteam = () => {
/*
            gsap.from('.c-recruitmentteam .swiper', {
                scrollTrigger: {
                    trigger: '.c-recruitmentteam .swiper',
                    scrub: 2.2,
                    start: 'top bottom',
                    end: 'top center'
                },      
                xPercent: 15
            });
*/
            
            /*
ScrollTrigger.matchMedia({
                "(max-width: 768px)": () => {     
                    
                    let tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: ".c-recruitmentteam .swiper",
                            pin: true,
                            pinSpacing: true,
                            //markers: true,
                            start: "center center",
                            end: "+=1500", 
                            scrub: 1,
                        }
                    });                    
                    
                    const items = gsap.utils.toArray(".c-recruitmentteam .swiper .swiper-slide:not(:first-of-type)"); 
            
                    items.forEach((item, i) => {
                        tl.fromTo(item, {
                            yPercent: 200,
                            scaleX: 1.5,
                            scaleY: 1.5,
                        }, {
                            yPercent: -100,
                            scaleX: 1,
                            scaleY: 1,
                        })
                    });
                }
            });
            
            ScrollTrigger.matchMedia({
                "(min-width: 769px)": () => { 
                    
                    gsap.from('.c-recruitmentteam .swiper', {
                        scrollTrigger: {
                            trigger: '.c-recruitmentteam .swiper',
                            scrub: 2.2,
                            start: 'top bottom',
                            end: 'botom 10%'
                        },      
                        xPercent: -25
                    });                   
                }
            });
            
            window.addEventListener('refresh', () => {
                ScrollTrigger.refresh();
            })
*/
        }
        
        const ring = () => {
            const tl = gsap.timeline({
                repeat: -1
            })
            .to(document.querySelector(".c-hero__ring .dashes"), {
                duration: 20,
                rotate: -360,
                ease: Linear.easeNone
            });
            
            gsap.from(document.querySelector('.c-hero'), {
                scrollTrigger: {
                    trigger: '.c-hero',
                    start: 'top top',
                    onEnterBack: () => {
                        tl.play();
                    },
                    onLeaveBack: () => {
                        tl.play();
                    },
                    onLeave: () => {
                        tl.pause();
                    }
                }, 
            });
        }

        const robot = () => {
            ScrollTrigger.matchMedia({
                "(min-width: 769px)": () => {
                    gsap.to(document.querySelectorAll('.c-search__robot'), 2, {
                        repeat: -1,
                        yoyo: true,
                        y: 30,
                        ease: Power1.easeInOut
                    });
                }
            });
        }
        
        const sections = () => {
            ScrollTrigger.matchMedia({
                "(max-width: 768px)": () => {     
                    
                    let tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: ".c-sections .swiper",
                            pin: true,
                            pinSpacing: true,
                            start: "center center",
                            end: "+=1500", 
                            scrub: 1,
                        }
                    });                    
                    
                    const items = gsap.utils.toArray(".c-sections .swiper .swiper-slide:not(:first-of-type)"); 
            
                    items.forEach((item, i) => {
                        tl.fromTo(item, {
                            yPercent: 200,
                            scaleX: 1.5,
                            scaleY: 1.5,
                        }, {
                            yPercent: -100,
                            scaleX: 1,
                            scaleY: 1,
                        })
                    });
                }
            });
            
            ScrollTrigger.matchMedia({
                "(min-width: 769px)": () => { 
                    
                    gsap.from('.c-sections .swiper', {
                        scrollTrigger: {
                            trigger: '.c-sections .swiper',
                            scrub: 2.2,
                            start: 'top bottom',
                            end: 'botom 10%'
                        },      
                        xPercent: -25
                    });                   
                }
            });
            
            window.addEventListener('refresh', () => {
                ScrollTrigger.refresh();
            })
        }
        
        const social = () => {   
            ScrollTrigger.matchMedia({
                "(min-width: 769px)": () => {     
                    gsap.from(document.querySelectorAll('.c-social .swiper .swiper-slide:nth-of-type(even)'), {
                        scrollTrigger: {
                            trigger: '.c-social',
                            start: 'top center',
                            end: 'bottom bottom',
                            scrub: 2,
                        },
                        opacity: .5,
                        yPercent: 50
                    });
                }
            })
        }
        
        const teams = () => {
            ScrollTrigger.matchMedia({
                "(max-width: 768px)": () => {     
                    
                    let tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: ".c-teams .swiper",
                            pin: true,
                            pinSpacing: true,
                            //markers: true,
                            start: "center center",
                            end: "+=1500", 
                            scrub: 1,
                        }
                    });                    
                    
                    const items = gsap.utils.toArray(".c-teams .swiper .swiper-slide:not(:first-of-type)"); 
            
                    items.forEach((item, i) => {
                        tl.fromTo(item, {
                            yPercent: 150,
                           // opacity: 0,
                            scaleX: 1.2,
                            scaleY: 1.2,
                        }, {
                            yPercent: -100,
                            //opacity: 1,
                            scaleX: 1,
                            scaleY: 1,
                        })
                    });
                }
            });
            
            ScrollTrigger.matchMedia({
                "(min-width: 769px)": () => { 
                    
                    gsap.from('.c-teams .swiper-slide', {
                        scrollTrigger: {
                            trigger: '.c-teams',
                            start: 'top center',
                            toggleActions: "play complete complete reverse",
                            stagger: { // wrap advanced options in an object
                                each: 0.2,
                                from: "center",
                                grid: "auto",
                                ease: "power2.inOut",
                                repeat: -1 // Repeats immediately, not waiting for the other staggered animations to finish
                              }
                        }, 
                        y: 60,
                        scale: .9
                    });                   
                }
            });
        }
        
        const topbar = () => {
            ScrollTrigger.matchMedia({
                "(min-width: 920px)": () => {
                    gsap.to(document.querySelector('.c-topbar'), {
                        scrollTrigger: {
                            trigger: '.o-container',
                            pin: '.c-topbar',
                            pinSpacing: false,
                            onToggle: self => self.isActive ? 
                                document.querySelector('.c-nav__mini').classList.add('is-active') : 
                                document.querySelector('.c-nav__mini').classList.remove('is-active'),
                            start: () => document.querySelector('.c-topbar').offsetHeight + 2 + 'px top',
                            //end: "bottom center",     
                            scrub: 1,
                        },
                    });
                    
                    gsap.to(document.querySelector('.c-topbar .c-nav__mini'), {
                        scrollTrigger: {
                            trigger: '.c-topbar',
                            start: "top top",
                            toggleActions: "play complete complete reverse",
                            scrub: 1
                        },
                        yPercent: 150
                    });
                }
            });
        }
        
        const work = () => {
            gsap.from(document.querySelector('.c-work .o-article'), {
                scrollTrigger: {
                    trigger: '.c-work',
                    start: 'top bottom',
                    end: 'center center',
                    scrub: 1,
                }, 
                opacity: 0, 
                x: -50
            });
        }
        
        const values = () => {
            ScrollTrigger.matchMedia({
                "(min-width: 769px)": () => { 
                    ScrollTrigger.batch(".c-values .swiper-slide", {
                        onEnter: elements => {
                            gsap.from(elements, {
                                autoAlpha: 0,
                                y: 60,
                                stagger: 0.25,
                                scale: .9
                            });
                        },
                    });
                }
            });
        }        
        
        document.querySelectorAll('.fadeIn').length > 0 ? fadeIn() : false;
        document.querySelectorAll('.o-hexagon').length > 0 ? hexagon() : false
        //document.querySelector('.c-hero__ring') ? ring() : false;
        document.querySelector('.c-process') ? process() : false
        document.querySelector('.c-recruitmentteam') ? recruitteam() : false;
        document.querySelectorAll('.c-search__robot') ? robot() : false;
        document.querySelector('.c-social') ? social() : false
        //document.querySelector('.c-teams') ? teams() : false
        document.querySelector('.c-topbar') ? topbar() : false
        document.querySelector('.c-values') ? values() : false
        document.querySelector('.c-work') ? work() : false
        
        /*
setTimeout(() => {
            document.querySelector('.c-sections') ? sections() : false;
            ScrollTrigger.refresh();
        }, 100);
*/

        /*
setTimeout(() => {
            document.querySelector('.c-recruitmentteam') ? recruitteam() : false;
        }, 200);
*/
    };

}, false)
