import { gsap, Power1 } from "gsap";
import Swiper, { Autoplay, EffectFade, FreeMode, Grid, Lazy, Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import 'swiper/modules/effect-fade/effect-fade.scss';
import 'swiper/modules/grid/grid.scss';
import 'swiper/modules/lazy/lazy.scss';
import 'swiper/modules/pagination/pagination.scss';

document.addEventListener('DOMContentLoaded', () => {

    const benefits = document.querySelectorAll('.js-benefitsSwiper'),
          benefitsOneRow = document.querySelector('.js-benefitsOneRow'),
          benefitsHome = document.querySelectorAll('.js-benefitsHomeSwiper'),
          countup = document.querySelector('.js-countupSwiper'),
          meet = document.querySelector('.js-meetSwiper'),
          hero = document.querySelector('.js-heroSwiper'),
          heroMobile = document.querySelector('.js-heroSwiper--mobile'),
          heroDesktop = document.querySelector('.js-heroSwiper--desktop'),
          process = document.querySelectorAll('.js-processSwiper'),
          recruitmentteam = document.querySelector('.js-recruitmentteamSwiper'),
          sections = document.querySelector('.js-sectionsSwiper'),
          social = document.querySelector('.js-socialSwiper'),
          teams = document.querySelector('.js-teamsSwiper'),
          values = document.querySelector('.js-valuesSwiper'),
          divisions = document.querySelector('.js-divisionSwiper'),
          divisionsEn = document.querySelector('.js-divisionEnSwiper');

    const speed = 600;

    window.benefitsCarousel = () => {
        //v-if offer ukrywa w DOM sekcje
        const benefits = document.querySelectorAll('.js-benefitsSwiper');
        let swiper = new Array(), status = false;

        const carousels = {
            destroy: function(el) {
                if (status === true) {
                    for (let i = 0; i < benefits.length; i++) {
                        swiper[i].destroy();
                    }

                    status = false;
                }
            },

            start: function(el) {
                for (let i = 0; i < benefits.length; i++) {
                    swiper[i] = new Swiper(benefits[i], {
                        modules: [Navigation, Pagination],
                        breakpoints: {
                            511: {
                        	    spaceBetween: 20,
                        	    slidesPerGroup: 2,
                                slidesPerView: 2,
                            },
                    	    769: {
                        	    spaceBetween: 20,
                                slidesPerView: 2,
                            }
                        },
                        followFinger: false,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                            dynamicBullets: true,
                            dynamicMainBullets: 1
                        },
                        slidesPerView: 'auto',
                        spaceBetween: 10,
                        speed: speed
            	    });
                }

                status = true;
            },

            toggle: function() {
                if (window.innerWidth <= 768) {
                    status === false ? carousels.start(benefits) : false;
                } else {
                    carousels.destroy(benefits);
                }
            }
        }

        carousels.toggle();
        window.addEventListener('resize', carousels.toggle);
    }

    const benefitsHomeCarousel = () => {
        let swiper = new Array();

        for (let i = 0; i < benefitsHome.length; i++) {
            swiper[i] = new Swiper(benefitsHome[i], {
                modules: [Grid, Navigation, Pagination],
                breakpoints: {
                    511: {
                        slidesPerGroup: 1,
                        slidesPerView: 2,
                        grid: {
                            fill: 'row',
                            rows: 2,
                        },
                    },
            	    769: {
                	    slidesPerGroup: 4,
                        slidesPerView: 4,
                        grid: {
                            fill: 'row',
                            rows: 2,
                        },
                    }
                },
                navigation: {
                    nextEl: benefitsHome[i].querySelector('.swiper-button-next'),
                    prevEl: benefitsHome[i].querySelector('.swiper-button-prev'),
                },
                pagination: {
                    el: benefitsHome[i].querySelector('.swiper-pagination'),
                    clickable: true
                },
                slidesPerGroup: 2,
                slidesPerView: 2,
                spaceBetween: 20,
                speed: speed
    	    });
 	    }
    }

    const benefitsOneRowCarousel = () => {
        const swiper = new Swiper(benefitsOneRow, {
            modules: [Navigation, Pagination],
            breakpoints: {
                511: {
            	    spaceBetween: 20,
            	    slidesPerGroup: 2,
                    slidesPerView: 2,
                },
        	    769: {
            	    spaceBetween: 20,
                    slidesPerView: 4,
                }
            },
            followFinger: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true,
                dynamicMainBullets: 1
            },
            slidesPerView: 'auto',
            spaceBetween: 10,
            speed: speed
	    });
    }

    const countupCarousel = () => {
        const swiper = new Swiper(countup, {
            modules: [Navigation, Pagination],
            createElements: true,
            breakpoints: {
                511: {
            	    spaceBetween: 20,
            	    slidesPerGroup: 2,
                    slidesPerView: 2,
                },
        	    769: {
            	    spaceBetween: 20,
                    slidesPerView: 4,
                }
            },
            followFinger: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: countup.querySelector('.swiper-pagination'),
                clickable: true
            },
            slidesPerView: 'auto',
            spaceBetween: 10,
            speed: speed
        })
    }

    const meetCarousel = () => {
        let dep = false;

        const swiper = new Swiper(meet, {
            modules: [Lazy, Navigation, Pagination],
            createElements: true,
            breakpoints: {
                511: {
            	    spaceBetween: 20,
            	    slidesPerGroup: 2,
                    slidesPerView: 'auto',
                },
        	    769: {
            	    spaceBetween: 20,
                    slidesPerView: 1,
                }
            },
            followFinger: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            slidesPerView: 'auto',
            spaceBetween: 10,
            speed: speed,
            lazy: true,
            on: {
                init: function() {
                    dep = true;
                },
                slideChangeTransitionStart: function() {
                    if (dep === true) {
                        if (window.innerWidth > 768) {
                            let tl = gsap.timeline();
                            tl.set(meet.querySelector('.swiper-slide-active article'), {
                               opacity: 0
                            })

                            tl.to(meet.querySelector('.swiper-slide-active article'), {
                               duration: .5, scale: 1.1, opacity: 1
                            }, '+=.5')

                            tl.to(meet.querySelector('.swiper-slide-active article'), {
                               x: 40
                            }, '-=1.5')

                            tl.to(meet.querySelector('.swiper-slide-active article'), {
                               duration: .8, x: 0, scale: 1, ease: Power1.easeOut
                            }, '-=.5')
                        }
                    }
                }
            }
        })
    }

    const heroCarousel = () => {
        let dep = false,
            mobile = false,
            status = false,
            swiper, moveby = 0;

        const carousels = {
            destroy: function(el) {
                status === true ? swiper.destroy() : false;
                status = false;
            },

            startDesktop: function(el) {
                swiper = new Swiper(heroDesktop, {
                    modules: [Autoplay],
                    allowTouchMove: false,
                    autoplay: {
                        delay: 3000
                    },
                    createElements: true,
                    loop: true,
                    preloadImages: false,
                    speed: 400,
                    on: {
                        init: function() {
                            dep = true;
                        },
                        slideChangeTransitionStart: function() {
                            if (dep === true) {
                                if (window.innerWidth > 510) {
                                    let tl = gsap.timeline();

                                    if (document.querySelector('.c-hero .swiper-slide-active .o-slogan').classList.contains('left')) {
                                        moveby = -100;
                                    } else {
                                        moveby = 100
                                    }

                                    tl.to(document.querySelector('.c-hero .swiper-slide-active'), {
                                       duration: .6, scale: 1.1,
                                    })

                                    tl.to(document.querySelector('.c-hero .swiper-slide-active'), {
                                       x: -20
                                    }, '-=1')

                                    tl.to(document.querySelector('.c-hero .swiper-slide-active'), {
                                       duration: 1, x: 0, scale: 1
                                    }, '-=.5')

                                    tl.from(document.querySelector('.c-hero .swiper-slide-active .o-slogan'), {
                                       duration: 1.2, opacity: 0, x: moveby, scale: .8, ease: 'elastic.out(1, .8)',
                                    }, '-=.5')
                                  /*

                                    tl.from(document.querySelectorAll('.c-hero .swiper-slide-active .o-slogan p'), {
                                       duration: .5, opacity: 0, x: moveby,
                                    }, '-=.9')
    */
                                }
                            }
                        }
                    },
                    speed: speed,
        	    });

                mobile = false;
        	    status = true;
            },

            startMobile: function(el) {
                swiper = new Swiper(heroMobile, {
                    allowTouchMove: false,
                    createElements: true,
                    loop: true,
                    preloadImages: false,
                    speed: 400,
        	    });

                mobile = true;
        	    status = true;

        	    document.querySelector('.js-ring').addEventListener('click', function() {
                    swiper.slideNext();
                    document.querySelector('#hero').setAttribute('data-active', 'slide-0'+swiper.realIndex);
                })
            },

 /*
           toggle: function() {
                if (window.innerWidth <= 500) {
                    if (mobile === false) {
                        carousels.destroy();
                        //carousels.startMobile();
                    }
                } else {
                    if (mobile === true) {
                        carousels.destroy();
                        carousels.startDesktop();
                    } else {
                        if (status != true) {
                            carousels.startDesktop();
                        }
                    }
                }
            }
*/
        }

        /*
const carousels = {
            destroy: function(el) {
                status === true ? swiper.destroy() : false;
                status = false;
            },

            startDesktop: function(el) {
                swiper = new Swiper(heroDesktop, {
                    allowTouchMove: false,
                    createElements: true,
                    loop: true,
                    preloadImages: false,
                    speed: 400,
                    on: {
                        init: function() {
                            dep = true;
                        },
                        slideChangeTransitionStart: function() {
                            if (dep === true) {
                                let tl = gsap.timeline();

                                tl.to(document.querySelector('.c-hero .swiper-slide-active'), {
                                   duration: .6, scale: 1.1,
                                })

                                tl.to(document.querySelector('.c-hero .swiper-slide-active'), {
                                   x: -20
                                }, '-=1')

                                tl.to(document.querySelector('.c-hero .swiper-slide-active'), {
                                   duration: 1, x: 0, scale: 1
                                }, '-=.5')
                            }
                        }
                    },
                    speed: speed,
        	    });

                mobile = false;
        	    status = true;

        	    if (document.querySelector('.js-ring')) {
            	    document.querySelector('.js-ring').addEventListener('click', function() {
                        swiper.slideNext();
                        document.querySelector('#hero').setAttribute('data-active', 'slide-0'+swiper.realIndex);
                    })
                }
            },

            startMobile: function(el) {
                swiper = new Swiper(heroMobile, {
                    allowTouchMove: false,
                    createElements: true,
                    loop: true,
                    preloadImages: false,
                    speed: 400,
        	    });

                mobile = true;
        	    status = true;

        	    document.querySelector('.js-ring').addEventListener('click', function() {
                    swiper.slideNext();
                    document.querySelector('#hero').setAttribute('data-active', 'slide-0'+swiper.realIndex);
                })
            },

            toggle: function() {
                if (window.innerWidth <= 500) {
                    if (mobile === false) {
                        carousels.destroy();
                        carousels.startMobile();
                    }
                } else {
                    if (mobile === true) {
                        carousels.destroy();
                        carousels.startDesktop();
                    } else {
                        if (status != true) {
                            carousels.startDesktop();
                        }
                    }
                }
            }
        }
*/

        carousels.startDesktop();
        //carousels.toggle();
        //window.addEventListener('resize', carousels.toggle);
    }

    const processCarousel = () => {
        let swiper = new Array(), status = false;

        const carousels = {
            destroy: function(el) {
                for (let i = 0; i < process.length; i++) {
                    status === true ? swiper[i].destroy() : false;
                    status = false;
                }
            },

            start: function(el) {
                for (let i = 0; i < process.length; i++) {
                    swiper[i] = new Swiper(process[i], {
                        modules: [Navigation, Pagination],
                        breakpoints: {
                            511: {
                        	    spaceBetween: 20,
                        	    slidesPerGroup: 2,
                                slidesPerView: 2,
                            },
                    	    769: {
                        	    spaceBetween: 20,
                                slidesPerView: 4,
                            }
                        },
                        followFinger: false,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true
                        },
                        slidesPerView: 'auto',
                        spaceBetween: 10,
                        speed: speed
            	    });

            	    status = true;
                }
            },

            toggle: function() {
                if (window.innerWidth <= 768) {
                    status === false ? carousels.start(process) : false;
                } else {
                    carousels.destroy(process);
                }
            }
        }

        carousels.toggle();
        window.addEventListener('resize', carousels.toggle);
    }

    const recruitmentteamCarousel = () => {
        let swiper, status = false, dep = false, desktop = true;

        const carousels = {
            destroy: function(el) {
                status === true ? swiper.destroy() : false;
                status = false;
            },

            startDesktop: function(el) {
                swiper = new Swiper(recruitmentteam, {
                    modules: [Lazy, Navigation],
                    lazy: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    slidesPerView: 3,
                    spaceBetween: 20,
                    speed: speed,
        	    });

        	    status = true;
        	    desktop = true;
            },

            startMobile: function(el) {
                swiper = new Swiper(recruitmentteam, {
                    createElements: true,
                    breakpoints: {
                        511: {
                    	    spaceBetween: 16,
                    	    slidesPerGroup: 2,
                            slidesPerView: 2,
                        },
                    },
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: recruitmentteam.querySelector('.swiper-button-next'),
                        prevEl: recruitmentteam.querySelector('.swiper-button-prev'),
                    },
                    pagination: {
                        el: recruitmentteam.querySelector('.swiper-pagination'),
                        clickable: true
                    },
                    followFinger: false,
                    slidesPerGroup: 1,
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                    speed: speed,
        	    });

        	    status = true;
        	    desktop = false;
            },

            toggle: function() {
                if (window.innerWidth > 768) {
                    if (status === false) {
                        carousels.startDesktop(recruitmentteam)
                    } else {
                        if (desktop === false) {
                            carousels.destroy(recruitmentteam);
                            carousels.startDesktop(recruitmentteam)
                        }
                    }
                } else {
                    if (desktop === true) {
                        carousels.destroy(recruitmentteam);
                    }

                    if (status === false) {
                        carousels.startMobile();
                    }
                }
            }
        }

        carousels.toggle();
        window.addEventListener('resize', carousels.toggle);
    }

   /*
 const recruitmentteamCarousel = () => {
        let swiper, status = false;

        const carousels = {
            destroy: function(el) {
                status === true ? swiper.destroy() : false;
                status = false;
            },

            start: function(el) {
                swiper = new Swiper(recruitmentteam, {
                    modules: [Lazy, Navigation],
                    createElements: true,
                    lazy: true,
                    navigation: {
                        nextEl: document.querySelector('.c-recruitmentteam .swiper-button-next'),
                        prevEl: document.querySelector('.c-recruitmentteam .swiper-button-prev'),
                    },
                    slidesPerView: 3,
                    spaceBetween: 20,
                    speed: speed,
        	    });

        	    status = true;
            },

            toggle: function() {
                if (window.innerWidth > 768) {
                    status === false ? carousels.start(recruitmentteam) : false;
                } else {
                    carousels.destroy(recruitmentteam);
                }
            }
        }

        carousels.toggle();
        window.addEventListener('resize', carousels.toggle);
    }
*/

    const socialCarousel = () => {
        let swiper, status = false;

        const carousels = {
            destroy: function(el) {
                status === true ? swiper.destroy() : false;
                status = false;
            },

            start: function(el) {
                swiper = new Swiper(social, {
                    modules: [Navigation, Pagination],
                    createElements: true,
                    breakpoints: {
                        511: {
                    	    spaceBetween: 20,
                            slidesPerGroup: 2,
                            slidesPerView: 2,
                        },
                	    769: {
                    	    spaceBetween: 20,
                            slidesPerView: 4,
                        }
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        dynamicBullets: true,
                        dynamicMainBullets: 1
                    },
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                    speed: speed
                })

        	    status = true;
            },

            toggle: function() {
                if (window.innerWidth <= 768) {
                    status === false ? carousels.start(social) : false;
                } else {
                    carousels.destroy(social);
                }
            }
        }

        carousels.toggle();
        window.addEventListener('resize', carousels.toggle);
    }

    const sectionsCarousel = () => {
        let swiper, status = false;

        const carousels = {
            destroy: function(el) {
                status === true ? swiper.destroy() : false;
                status = false;
            },

            start: function(el) {
                swiper = new Swiper(sections, {
                    modules: [Navigation, Pagination],
                    createElements: true,
                    breakpoints: {
                        511: {
                    	    spaceBetween: 20,
                    	    slidesPerGroup: 2,
                            slidesPerView: 2,
                        },
                	    769: {
                    	    spaceBetween: 20,
                            slidesPerView: 3,
                        }
                    },
                    followFinger: false,
                    navigation: {
                        nextEl: document.querySelector('.c-sections .swiper-button-next'),
                        prevEl: document.querySelector('.c-sections .swiper-button-prev'),
                    },
                    pagination: {
                        el: document.querySelector('.c-sections .swiper-pagination'),
                        clickable: true
                    },
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                    speed: speed,
        	    });

        	    status = true;
            },

            /*
toggle: function() {
                if (window.innerWidth > 768) {
                    status === false ? carousels.start(sections) : false;
                } else {
                    carousels.destroy(sections);
                }
            }
*/
        }

        carousels.start();

//        carousels.toggle();
  //      window.addEventListener('resize', carousels.toggle);
    }

    const teamsCarousel = () => {
        let swiper, status = false, dep = false, desktop = true;

        const carousels = {
            destroy: function(el) {
                status === true ? swiper.destroy() : false;
                status = false;
            },

            startDesktop: function(el) {
                swiper = new Swiper(teams, {
                    modules: [Lazy],
                    lazy: true,
                    slidesPerView: 5,
                    spaceBetween: 16,
                    speed: speed,
        	    });

        	    status = true;
        	    desktop = true;
            },

            startMobile: function(el) {
                swiper = new Swiper(teams, {
                    createElements: true,
                    breakpoints: {
                        511: {
                    	    spaceBetween: 16,
                    	    slidesPerGroup: 2,
                            slidesPerView: 2,
                        },
                    },
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: teams.querySelector('.swiper-button-next'),
                        prevEl: teams.querySelector('.swiper-button-prev'),
                    },
                    pagination: {
                        el: teams.querySelector('.swiper-pagination'),
                        clickable: true
                    },
                    followFinger: false,
                    slidesPerGroup: 1,
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                    speed: speed,
        	    });

        	    status = true;
        	    desktop = false;
            },

            toggle: function() {
                if (window.innerWidth > 1024) {
                    if (status === false) {
                        carousels.startDesktop(teams)
                    } else {
                        if (desktop === false) {
                            carousels.destroy(teams);
                            carousels.startDesktop(teams)
                        }
                    }
                }

                if ((window.innerWidth > 769) && (window.innerWidth <= 1024)) {
                    if (status === true) {
                        carousels.destroy(teams);
                    }
                }

                if (window.innerWidth < 769) {
                    if (desktop === true) {
                        if (status === true) {
                            carousels.destroy(teams);
                        }
                    }
                    if (status === false) {
                        carousels.startMobile();
                    }
                }
            }
        }

        carousels.toggle();
        window.addEventListener('resize', carousels.toggle);
    }

    /*
const teamsCarousel = () => {
        let swiper, status = false;

        const carousels = {
            destroy: function(el) {
                status === true ? swiper.destroy() : false;
                status = false;
            },

            start: function(el) {
                swiper = new Swiper(teams, {
                    modules: [Lazy],
                    lazy: true,
                    slidesPerView: 5,
                    spaceBetween: 16,
                    speed: speed,
        	    });

        	    status = true;
            },

            toggle: function() {
                if (window.innerWidth > 1024) {
                    status === false ? carousels.start(teams) : false;
                } else {
                    carousels.destroy(teams);
                }
            }
        }

        carousels.toggle();
        window.addEventListener('resize', carousels.toggle);
    }
*/

    const valuesCarousel = () => {
        const swiper = new Swiper(values, {
            modules: [Navigation, Pagination],
            createElements: true,
            breakpoints: {
                511: {
            	    spaceBetween: 12,
            	    slidesPerGroup: 2,
                    slidesPerView: 2,
                },
        	    769: {
            	    spaceBetween: 12,
                    slidesPerView: 5,
                }
            },
            followFinger: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            slidesPerView: 'auto',
            spaceBetween: 10,
            speed: speed,
        })
    }

    const divisionCarousel = () => {
        const swiper = new Swiper(divisions, {
            modules: [Navigation, Pagination],
            createElements: true,
            breakpoints: {
                511: {
            	    spaceBetween: 12,
            	    slidesPerGroup: 2,
                    slidesPerView: 2,
                },
        	    769: {
            	    spaceBetween: 12,
                    slidesPerView: 5,
                }
            },
            followFinger: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            slidesPerView: 'auto',
            spaceBetween: 10,
            speed: speed,
        })
    }

    const divisionEnCarousel = () => {
        const swiper = new Swiper(divisionsEn, {
            modules: [Navigation, Pagination],
            createElements: true,
            breakpoints: {
                511: {
            	    spaceBetween: 12,
            	    slidesPerGroup: 2,
                    slidesPerView: 2,
                },
                1025: {
            	    spaceBetween: 12,
                    slidesPerView: 4,
                },
                1280: {
            	    spaceBetween: 12,
                    slidesPerView: 5,
                }
            },
            followFinger: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            slidesPerView: 'auto',
            spaceBetween: 10,
            speed: speed,
        })
    }

    window.carousels = function() {
        benefits.length > 0 ? benefitsCarousel() : false;
        benefitsHome.length > 0 ? benefitsHomeCarousel() : false;
        benefitsOneRow ? benefitsOneRowCarousel() : false;
        countup ? countupCarousel() : false;
        meet ? meetCarousel() : false;
        hero ? heroCarousel() : false;
        process.length > 0 ? processCarousel() : false;
        recruitmentteam ? recruitmentteamCarousel() : false;
        sections ? sectionsCarousel() : false;
        social ? socialCarousel() : false;
        teams ? teamsCarousel() : false;
        values ? valuesCarousel() : false;
        divisions ? divisionCarousel() : false;
        divisionsEn ? divisionEnCarousel() : false;
    }

}, false)
